import React from 'react';
import styles from './FlexiLoanCTABox.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import TickInCircleSVG from 'aphrodite-shared/icons/TickInCircleSVG';
import classnames from 'classnames';
import IwLink from 'aphrodite-shared/ui/IwLink/IwLink';
import InfoIcon from 'aphrodite-shared/SVGComponents/InfoIcon';
import GatsbyImage from 'gatsby-image';

const FlexiLoanCTABox = ({ content, className }) => {
    return (
        <div
            className={classnames(className, styles.FlexiLoanCTABox)}
            key={content.key}>
            <div className={styles.Image}>
                <GatsbyImage alt="iwoca logo" fluid={content.image.fluid} />
            </div>
            <div className={styles.Wrapper}>
                <p className={`${styles.h2} ${styles.DesktopHeader}`}>
                    With an iwoca Flexi-Loan you can
                </p>
                <p className={`${styles.h2} ${styles.MobileHeader}`}>
                    iwoca Flexi-Loan
                </p>
                <ul>
                    <li>
                        <TickInCircleSVG
                            className={styles.TickInCircle}
                            fill="#009967"
                        />
                        <p className={styles.BulletPoint}>
                            Borrow from £1,000 to £500,000 over 24 months
                        </p>
                    </li>
                    <li>
                        <TickInCircleSVG
                            className={styles.TickInCircle}
                            fill="#009967"
                        />
                        <p className={styles.BulletPoint}>
                            Get a decision in 1 working day
                        </p>
                    </li>
                    <li>
                        <TickInCircleSVG
                            className={styles.TickInCircle}
                            fill="#009967"
                        />
                        <p className={styles.BulletPoint}>
                            Repay early with no fees
                        </p>
                    </li>
                </ul>
                <div className={styles.CTAs}>
                    <a
                        className={styles.Link}
                        data-ga-id="article_cta_findoutmore__flexi_loan"
                        href="/flexi-loan/">
                        Find out more
                    </a>
                    <Button
                        colour="primary"
                        href="#apply"
                        dataGaId="article_cta__cta">
                        Apply now
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FlexiLoanCTABox;
