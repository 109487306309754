import React from 'react';

const YahooFinanceLogo = () => (
    <svg
        width="189"
        height="22"
        viewBox="0 0 189 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M68.6387 14.6069C68.2464 13.5209 68.048 12.2141 68.048 10.723C68.048 9.23325 68.2464 7.92584 68.6387 6.83986C69.0316 5.75132 69.578 4.85028 70.2804 4.14058C70.7016 3.7131 71.1671 3.35409 71.6737 3.06035C72.0146 2.86325 72.3716 2.69494 72.751 2.55736C73.6949 2.21563 74.7112 2.04412 75.7963 2.04412C76.9013 2.04412 77.9254 2.21563 78.8717 2.55736C79.8156 2.89973 80.6393 3.42768 81.3424 4.14058C82.0448 4.85156 82.5912 5.75388 82.9847 6.8437C83.3783 7.9348 83.5741 9.22877 83.5741 10.725C83.5741 12.2211 83.3783 13.5132 82.9847 14.6049C82.5912 15.696 82.0448 16.5971 81.3424 17.3093C80.8692 17.7899 80.3382 18.1841 79.7546 18.4964C79.4733 18.6462 79.1799 18.7799 78.8717 18.8906C77.9254 19.2337 76.9013 19.4052 75.7963 19.4052C74.7112 19.4052 73.6949 19.2337 72.751 18.8906C71.804 18.5489 70.9815 18.0222 70.2804 17.3093C70.1045 17.1314 69.9388 16.942 69.7828 16.7411C69.3148 16.1357 68.9315 15.4202 68.6387 14.6069ZM83.4387 3.58639C82.7414 2.96308 81.9337 2.44665 81.0284 2.04476C80.726 1.91037 80.4127 1.78942 80.0878 1.68063C78.7902 1.24547 77.3591 1.02661 75.7963 1.02661C74.2567 1.02661 72.8358 1.24547 71.5369 1.68063C71.37 1.73631 71.2056 1.79518 71.0451 1.85854C69.9555 2.28282 69.0047 2.85813 68.1931 3.58383C67.2608 4.41895 66.5372 5.43902 66.0197 6.64532C65.5022 7.85033 65.2441 9.22493 65.2441 10.7659C65.2441 12.3069 65.5022 13.6809 66.0197 14.8865C66.5372 16.0928 67.2608 17.1129 68.1931 17.9467C69.1241 18.7812 70.2387 19.4026 71.5369 19.8102C72.8358 20.2185 74.2567 20.4227 75.7963 20.4227C77.3591 20.4227 78.7902 20.2185 80.0878 19.8102C80.1892 19.7782 80.2888 19.7411 80.3889 19.7078C81.2692 19.4013 82.0615 18.9937 82.7658 18.483C83.0008 18.3134 83.2255 18.1329 83.4387 17.9416C84.3645 17.1135 85.0939 16.0851 85.6107 14.8833C86.1244 13.6834 86.387 12.2973 86.387 10.7646C86.387 9.23197 86.1244 7.84585 85.6107 6.64532C85.0945 5.44478 84.3645 4.41575 83.4387 3.58639Z"
            fill="#4D809E"
        />
        <path
            d="M61.4499 10.7559C61.4499 12.0832 61.2894 13.2306 60.9683 14.1982C60.6448 15.1658 60.1972 15.9657 59.6226 16.5973C59.0486 17.229 58.3738 17.6974 57.6001 18.0014C56.8265 18.3053 55.9866 18.4564 55.0839 18.4564C54.194 18.4564 53.3632 18.3053 52.5902 18.0014C51.8152 17.6974 51.1424 17.229 50.5684 16.5973C49.9931 15.9657 49.5443 15.1658 49.2233 14.1982C48.9016 13.2306 48.7404 12.0832 48.7404 10.7559C48.7404 9.42868 48.9016 8.28062 49.2233 7.31238C49.5443 6.34479 49.9931 5.54486 50.5684 4.91388C51.1424 4.28161 51.8152 3.81381 52.5902 3.51048C53.3632 3.2065 54.194 3.0542 55.0839 3.0542C55.9866 3.0542 56.8265 3.2065 57.6001 3.51048C58.3738 3.81381 59.0486 4.28161 59.6226 4.91388C60.1972 5.54486 60.6448 6.34479 60.9683 7.31238C61.2894 8.28062 61.4499 9.42868 61.4499 10.7559ZM64.2248 10.5473C64.2024 9.25333 63.9866 8.0912 63.5623 7.06729C63.1237 6.01138 62.5106 5.11674 61.7305 4.37888C61.7138 4.36416 61.7003 4.3456 61.683 4.33025L61.6817 4.32897C60.8753 3.58279 59.9102 3.01388 58.7879 2.62544C57.6643 2.23571 56.4252 2.04053 55.0756 2.04053C53.7388 2.04053 52.5106 2.23571 51.387 2.62544C50.2634 3.01388 49.2984 3.58279 48.4926 4.32897C47.6868 5.07514 47.0589 5.98834 46.612 7.06729C46.1645 8.14751 45.9404 9.37556 45.9404 10.7559C45.9404 12.1337 46.1645 13.3637 46.612 14.4426C47.0589 15.5229 47.6868 16.4361 48.4926 17.1816C49.2984 17.9278 50.2634 18.4839 51.387 18.8486C52.5106 19.2153 53.7388 19.3977 55.0756 19.3977C56.4252 19.3977 57.6643 19.2153 58.7879 18.8486C59.9102 18.4839 60.8753 17.9278 61.6817 17.1816C62.4868 16.4361 63.1141 15.5229 63.5623 14.4426C64.0091 13.3637 64.2338 12.1337 64.2338 10.7559V10.7546V10.754C64.2338 10.6836 64.2261 10.6171 64.2248 10.5473Z"
            fill="#4D809E"
        />
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="17"
            height="22">
            <path d="M0 0H16.9714V21.431H0V0Z" fill="#4D809E" />
        </mask>
        <g mask="url(#mask0)">
            <path
                d="M12.8828 6.70073C13.4857 5.65634 14.3589 4.21007 14.886 3.31415C15.5653 2.16161 16.1239 1.28361 16.4109 0.854203C16.635 0.516313 16.7929 0.280813 16.9714 0.00051789C16.9701 0.00115783 16.9682 0.00179777 16.9669 0.00307765C16.9682 0.00179777 16.9701 0.00115783 16.9714 0.00051789C16.9271 0.0133168 16.8687 0.0261156 16.8141 0.0395544C16.6594 0.0766712 16.5002 0.108028 16.3377 0.131706C16.2716 0.141945 16.1997 0.149625 16.1284 0.156664C15.9807 0.172023 15.835 0.180982 15.6892 0.184182C15.6456 0.185462 15.6064 0.189941 15.5628 0.189941C15.0697 0.189941 14.5894 0.123387 14.1509 0.00051789C12.9091 2.22816 8.70176 9.03716 8.48603 9.43329C8.26902 9.03716 4.06228 2.232 2.82054 0.00371758C2.82054 0.00371758 2.81926 0.00499748 2.81798 0.00563742L2.82054 0.00435754C2.77303 0.0171564 2.70626 0.0350748 2.62985 0.0529933C2.51107 0.0817907 2.39037 0.104189 2.26902 0.124667C2.16244 0.141945 2.04558 0.156664 1.91653 0.169463C1.89213 0.171383 1.86774 0.172023 1.84334 0.173303C1.70851 0.184182 1.56533 0.191861 1.40802 0.191861C1.32777 0.191861 1.25457 0.186742 1.17881 0.184182C1.06067 0.178422 0.943178 0.172663 0.824398 0.157944C0.769823 0.152825 0.71268 0.148345 0.660032 0.140666C0.479614 0.114428 0.303692 0.0753913 0.132905 0.033795C0.0886035 0.021636 0.0430176 0.0120369 0 -0.00012207C0.178491 0.279533 0.336436 0.516953 0.559871 0.854203C0.845585 1.28361 1.4061 2.16161 2.08475 3.31351C2.61252 4.21007 3.48379 5.65634 4.08796 6.70009C4.69149 7.74384 5.32712 8.84454 5.93322 9.90365C6.69277 11.2347 7.14735 12.0359 7.29245 12.2913L7.29309 13.1021C7.29309 14.1932 7.27255 15.3681 7.23081 16.4253C7.18972 17.478 7.14285 20.3942 7.09277 21.431C7.5313 21.3074 7.99871 21.2415 8.48346 21.2415C8.96821 21.2415 9.44205 21.3074 9.87929 21.431C9.83049 20.3942 9.78555 17.4825 9.74253 16.4298C9.70144 15.372 9.68153 14.1964 9.68153 13.1059L9.68025 12.2906C10.1265 11.5041 10.5804 10.7042 11.0382 9.90365C11.6437 8.84518 12.2793 7.74384 12.8828 6.70073Z"
                fill="#4D809E"
            />
        </g>
        <path
            d="M21.143 13.1176C20.5844 13.1176 18.6429 13.0671 18.1125 13.0421C19.4852 9.58899 20.96 5.82996 21.143 5.0985C21.3254 5.82996 22.7931 9.58835 24.1664 13.0421C23.6368 13.0658 21.7003 13.1176 21.143 13.1176ZM22.2814 2.63664C22.2223 2.48689 22.1671 2.3493 22.1279 2.24243C22.1209 2.24499 22.1157 2.24755 22.1093 2.25075C21.8095 2.36786 21.4891 2.42674 21.1372 2.42674C20.8181 2.42674 20.5144 2.37554 20.2319 2.27443C20.2114 2.26675 20.1709 2.25075 20.1517 2.24307C20.1542 2.24307 20.1568 2.24499 20.1594 2.24563C20.1568 2.24435 20.1542 2.24307 20.151 2.24243C20.1125 2.3493 20.056 2.48689 19.9976 2.63664C18.8753 5.48951 14.9709 15.1763 13.134 18.6877C13.0551 18.84 12.9164 19.1101 12.8721 19.1939C13.3164 19.0691 13.7902 19.0064 14.2878 19.0064C14.7751 19.0064 15.2387 19.0691 15.6785 19.1939C15.674 19.1907 15.6701 19.1875 15.6663 19.1849C15.6701 19.1875 15.674 19.1907 15.6785 19.1939C15.83 18.7927 16.6743 16.6943 17.6573 14.2273L17.6586 14.2267C18.0798 14.2145 18.56 14.1972 18.9793 14.1915C19.5295 14.1831 20.578 14.1799 21.127 14.1799H21.1636C21.6984 14.1799 22.7552 14.1838 23.3061 14.1915C23.7254 14.1979 24.1998 14.2151 24.6204 14.2267H24.621C25.6034 16.6936 26.4496 18.7939 26.6011 19.1939H26.6024C26.6075 19.1913 26.6114 19.1869 26.6159 19.1837H26.6172C26.6127 19.1869 26.6075 19.1907 26.6024 19.1939C27.0416 19.0691 27.509 19.0064 27.9963 19.0064C28.4926 19.0064 28.9607 19.0691 29.4043 19.1939C29.3973 19.1907 29.3844 19.1849 29.3767 19.1817C29.3844 19.1849 29.3973 19.1907 29.4043 19.1939H29.405C29.3632 19.1101 29.2245 18.8413 29.1456 18.689C27.3093 15.177 23.4037 5.49015 22.2814 2.63664Z"
            fill="#4D809E"
        />
        <path
            d="M44.9073 12.8958V8.68239C44.9073 7.60473 44.9272 6.54818 44.9689 5.51147C45.0093 4.4754 45.0466 3.31774 45.0954 2.24072C44.6517 2.36679 44.1875 2.43014 43.6899 2.43014C43.1975 2.43014 42.7217 2.36679 42.278 2.2388H42.2774L42.2787 2.25672C42.3288 3.38558 42.3827 4.52404 42.4232 5.52747C42.463 6.55074 42.4848 7.61241 42.4848 8.68239L42.488 10.1447H42.4829C41.7407 10.1587 41.0267 10.1722 40.3262 10.1863C39.5024 10.2023 38.6498 10.2106 37.7644 10.2106C36.8803 10.2106 36.0264 10.2023 35.2039 10.1863C34.5028 10.1722 33.7792 10.1587 33.037 10.1447L33.0421 8.68239C33.0421 7.61241 33.0626 6.55074 33.1031 5.52747C33.1423 4.52404 33.1968 3.36702 33.2476 2.23816C32.8065 2.36551 32.3371 2.43014 31.8453 2.43014C31.3464 2.43014 30.8745 2.36743 30.4309 2.242C30.479 3.31774 30.5156 4.4754 30.5567 5.51147C30.5978 6.54818 30.6177 7.60473 30.6177 8.68239V12.8958C30.6177 13.9741 30.5978 15.0351 30.5567 16.0808C30.5156 17.1239 30.4707 18.1753 30.4219 19.1877L30.4424 19.1762H30.4431L30.4219 19.1877C30.8655 19.0629 31.34 18.9976 31.8389 18.9976C32.3307 18.9976 32.7968 19.0616 33.2411 19.189C33.1904 18.1555 33.1448 17.0746 33.1057 16.0641C33.0659 15.0326 33.0447 13.967 33.0447 12.8958L33.0421 11.3094L33.0427 11.3087C33.783 11.2959 34.5105 11.2857 35.2077 11.2793C36.0386 11.271 36.8976 11.2665 37.7644 11.2665C38.6312 11.2665 39.4896 11.271 40.3217 11.2793C41.0196 11.2857 41.7381 11.2959 42.4784 11.3087H42.4848V12.8958C42.4848 13.967 42.463 15.0326 42.4232 16.0641C42.3827 17.0746 42.3345 18.1555 42.2851 19.189C42.7288 19.0616 43.2039 18.9976 43.6944 18.9976C44.1939 18.9976 44.6601 19.0629 45.1044 19.189C45.0549 18.1766 45.0093 17.1239 44.9689 16.0808C44.9272 15.0351 44.9073 13.9741 44.9073 12.8958Z"
            fill="#4D809E"
        />
        <path
            d="M92.5856 1.22548C92.5856 1.2242 92.5843 1.22292 92.5843 1.22164C92.5837 1.21972 92.5837 1.2178 92.5831 1.21652C92.567 1.15061 92.5426 1.08917 92.5169 1.02838C92.5105 1.01302 92.5067 0.995099 92.499 0.9791C92.4701 0.918946 92.4328 0.863271 92.3937 0.809515C92.384 0.795437 92.3776 0.780078 92.3673 0.766639C92.3673 0.766639 92.3667 0.766639 92.3661 0.765999C92.3661 0.765999 92.3654 0.764079 92.3648 0.763439C92.262 0.632891 92.1291 0.5241 91.9686 0.438988C91.8402 0.372434 91.6925 0.325718 91.5365 0.291801C91.5185 0.287961 91.5044 0.278362 91.4858 0.274522C91.4652 0.270683 91.4447 0.267483 91.4235 0.263003H91.4216C91.2174 0.230366 91.0254 0.230366 90.8463 0.263003C90.6678 0.295 90.5079 0.357715 90.3686 0.451147C90.3018 0.495303 90.2428 0.546498 90.1869 0.604093C90.1843 0.607293 90.1805 0.609213 90.1773 0.612412C90.1542 0.63673 90.1362 0.667448 90.1144 0.694325C90.0829 0.733362 90.0482 0.771119 90.02 0.815915C90.02 0.816555 90.0193 0.817834 90.0187 0.819114L90.0174 0.820394C89.925 0.972701 89.8608 1.15317 89.8274 1.36179C89.8261 1.37203 89.8184 1.42514 89.8165 1.44306C89.81 1.49745 89.7927 1.64208 89.7901 1.66704C89.6161 3.7187 89.3381 7.00609 89.2283 8.29878C88.8502 12.9249 88.3712 15.8636 88.2852 16.6955C88.4502 16.6718 88.6242 16.6744 88.8097 16.7032L88.811 16.7038C88.9959 16.7326 89.1635 16.7838 89.3124 16.8574V16.8567C89.4884 16.0383 89.9436 13.0971 91.0177 8.58163C91.315 7.31838 92.5657 1.96397 92.5709 1.94414C92.5792 1.91278 92.5869 1.8667 92.5985 1.79887C92.6331 1.59025 92.628 1.3989 92.5856 1.22548Z"
            fill="#4D809E"
        />
        <path
            d="M89.4281 19.2902C89.3228 19.159 89.1899 19.0483 89.0281 18.9625C88.8689 18.8768 88.684 18.8166 88.4798 18.7846C88.3796 18.7693 88.2808 18.7616 88.1857 18.7616C88.0907 18.7616 87.9963 18.7693 87.9077 18.7853C87.7286 18.8173 87.5674 18.8813 87.4281 18.9741C87.2888 19.0675 87.17 19.1929 87.0776 19.3439C86.9838 19.4963 86.9202 19.6786 86.8869 19.8879C86.8528 20.0965 86.8573 20.2898 86.8997 20.4626C86.9408 20.636 87.014 20.7909 87.118 20.9227C87.222 21.0539 87.3549 21.1627 87.5154 21.2439C87.6759 21.3265 87.8609 21.3834 88.0637 21.4148C88.1703 21.4327 88.2782 21.4404 88.3796 21.4404C88.4682 21.4404 88.5568 21.4353 88.639 21.4212C88.8169 21.393 88.9774 21.331 89.1186 21.2382C89.256 21.1447 89.3735 21.0212 89.4679 20.8677C89.5617 20.7166 89.6259 20.5336 89.6586 20.325C89.6913 20.1164 89.6875 19.9225 89.6464 19.7503C89.604 19.5762 89.5308 19.4214 89.4281 19.2902Z"
            fill="#4D809E"
        />
        <path
            d="M109.523 4.18652V5.30131H103.303V9.63053H108.784V10.7543H103.303V17.3035H100.078V4.18652H109.523Z"
            fill="#4D809E"
        />
        <mask
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="189"
            height="22">
            <path d="M0 21.4407H188.353V0H0V21.4407Z" fill="#4D809E" />
        </mask>
        <g mask="url(#mask1)">
            <path
                d="M112.342 17.3033H115.567V4.1864H112.342V17.3033Z"
                fill="#4D809E"
            />
        </g>
        <mask
            id="mask2"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="189"
            height="22">
            <path d="M0 21.4407H188.353V0H0V21.4407Z" fill="#4D809E" />
        </mask>
        <g mask="url(#mask2)">
            <path
                d="M118.94 4.18652H121.001L128.505 12.532V4.18652H130.53V17.5057H129.134L120.983 8.29496V17.3035H118.94V4.18652Z"
                fill="#4D809E"
            />
        </g>
        <mask
            id="mask3"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="189"
            height="22">
            <path d="M0 21.4407H188.353V0H0V21.4407Z" fill="#4D809E" />
        </mask>
        <g mask="url(#mask3)">
            <path
                d="M136.684 12.4951H140.648L138.698 8.25801L136.684 12.4951ZM139.974 3.90063L146.138 17.3036H142.838L141.193 13.7199H136.083L134.355 17.3036H132.155L138.578 3.90063H139.974Z"
                fill="#4D809E"
            />
        </g>
        <mask
            id="mask4"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="189"
            height="22">
            <path d="M0 21.4407H188.353V0H0V21.4407Z" fill="#4D809E" />
        </mask>
        <g mask="url(#mask4)">
            <path
                d="M147.765 4.18652H149.826L157.33 12.532V4.18652H159.354V17.5057H157.959L149.808 8.29496V17.3035H147.765V4.18652Z"
                fill="#4D809E"
            />
        </g>
        <mask
            id="mask5"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="189"
            height="22">
            <path d="M0 21.4407H188.353V0H0V21.4407Z" fill="#4D809E" />
        </mask>
        <g mask="url(#mask5)">
            <path
                d="M165.619 10.7359C165.619 11.5831 165.748 12.3325 166.008 12.9833C166.267 13.6342 166.61 14.1807 167.038 14.6229C167.467 15.0651 167.96 15.3998 168.518 15.6269C169.074 15.8541 169.652 15.968 170.249 15.968C170.712 15.968 171.142 15.888 171.539 15.7287C171.936 15.5687 172.31 15.3556 172.657 15.0881C173.006 14.8213 173.33 14.5109 173.632 14.1583C173.933 13.805 174.223 13.4319 174.5 13.039L175.85 14.0245C175.56 14.491 175.211 14.939 174.801 15.369C174.391 15.7991 173.931 16.1799 173.419 16.5107C172.908 16.8428 172.351 17.1071 171.747 17.3036C171.144 17.4994 170.505 17.5986 169.834 17.5986C168.701 17.5986 167.657 17.4341 166.705 17.1052C165.754 16.7769 164.931 16.3117 164.238 15.7095C163.546 15.1079 163.004 14.3867 162.616 13.5452C162.228 12.7037 162.034 11.7674 162.034 10.7359C162.034 10.0729 162.117 9.44189 162.284 8.8429C162.45 8.24391 162.689 7.68972 163 7.18032C163.311 6.67029 163.691 6.21209 164.141 5.80317C164.59 5.39488 165.098 5.04611 165.66 4.7575C166.225 4.46888 166.842 4.2481 167.514 4.09452C168.185 3.94093 168.897 3.86414 169.648 3.86414C170.407 3.86414 171.101 3.95117 171.732 4.12715C172.365 4.30186 172.938 4.5476 173.452 4.86373C173.966 5.17986 174.425 5.55615 174.829 5.99195C175.233 6.42839 175.587 6.90387 175.896 7.41966L174.528 8.3687C174.251 7.97577 173.951 7.60525 173.628 7.2584C173.304 6.91155 172.958 6.60757 172.588 6.34712C172.218 6.08538 171.831 5.87868 171.423 5.72509C171.017 5.57151 170.591 5.49471 170.148 5.49471C169.544 5.49471 168.968 5.60798 168.419 5.8358C167.872 6.06234 167.389 6.39767 166.974 6.83923C166.557 7.28207 166.227 7.82795 165.984 8.47941C165.741 9.12959 165.619 9.88217 165.619 10.7359Z"
                fill="#4D809E"
            />
        </g>
        <mask
            id="mask6"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="189"
            height="22">
            <path d="M0 21.4407H188.353V0H0V21.4407Z" fill="#4D809E" />
        </mask>
        <g mask="url(#mask6)">
            <path
                d="M188.353 4.18652V5.30131H181.755V9.63053H187.236V10.7543H181.755V15.9589H188.353V17.3035H178.529V4.18652H188.353Z"
                fill="#4D809E"
            />
        </g>
    </svg>
);

export default YahooFinanceLogo;
