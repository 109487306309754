import React from 'react';
import { graphql } from 'gatsby';
import MetaData from '../../../components/Layout/MetaData.jsx';
import NavBar from '../../../components/NavBar/NavBar';
import Links from './Links/Links';
// import Guide from './Guide/Guide';
import PressLogos from '../../../components/Press/PressLogos/PressLogos';
import Footer from '../../../components/Footer/Footer';
import styles from '../iwocaPage.module.scss';
import FlexiLoanCTABox from '../../../components/CTABoxes/FlexiLoanCTABox/FlexiLoanCTABox';

const FinanceExplained = ({ data }) => {
    const { contentfulPageFinanceExplained: pageData } = data;
    const {
        title,
        subheading,
        metaDescription,
        allowSearchEngineScraping,
        url,
        blogs,
        ctaBox
    } = pageData;
    return (
        <div className={styles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar />
            <Links blogs={blogs} title={title} subheading={subheading} />
            {/* <Guide guide={financeGuide} /> */}
            {/* <InsightsEmail trustpilotRating={trustpilot.score.trustScore} /> */}
            <div style={{ backgroundColor: '#143b6b', padding: '2rem' }}>
                <FlexiLoanCTABox content={ctaBox} />
            </div>
            <PressLogos />
            <Footer />
        </div>
    );
};

export default FinanceExplained;

export const query = graphql`
    query FinanceExplainedQuery($id: String!) {
        contentfulPageFinanceExplained(id: { eq: $id }) {
            title
            subheading
            url
            metaDescription
            allowSearchEngineScraping
            blogs {
                ...BlogsInfo
            }
            financeGuide {
                heading
                bookHeading
                bookSubHeading
                description
                ctaText
            }
            ctaBox {
                copy {
                    copy
                }
                callToAction {
                    url
                    buttonText
                    dataGaId
                    buttonText
                }
                image {
                    fluid(maxWidth: 1100, quality: 80) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
        }
    }

    fragment BlogsInfo on ContentfulFinanceExplainedBlogs {
        section1Title
        section1 {
            name
            slug
            titleTag
            displayName
            categoryTag {
                categoryTag
            }
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
        section2Title
        section2 {
            name
            slug
            titleTag
            displayName
            categoryTag {
                categoryTag
            }
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
        section3Title
        section3 {
            name
            slug
            titleTag
            displayName
            categoryTag {
                categoryTag
            }
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
        section4Title
        section4 {
            name
            slug
            titleTag
            displayName
            categoryTag {
                categoryTag
            }
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
        section5Title
        section5 {
            name
            slug
            titleTag
            displayName
            categoryTag {
                categoryTag
            }
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
        section6Title
        section6 {
            name
            slug
            titleTag
            displayName
            categoryTag {
                categoryTag
            }
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
        section7Title
        section7 {
            name
            slug
            titleTag
            displayName
            categoryTag {
                categoryTag
            }
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
        section8Title
        section8 {
            name
            slug
            titleTag
            displayName
            categoryTag {
                categoryTag
            }
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
        section9Title
        section9 {
            name
            slug
            displayName
            categoryTag {
                categoryTag
            }
            titleTag
            articleHeaderImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
    }
`;
