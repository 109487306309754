import React from 'react';
import styles from './MobileLinks.module.scss';
import IwAccordion from '../../../../../components/IwAccordion/IwAccordion';
import GatsbyLink from 'gatsby-link';
import { getContenthubArticleFullSlug } from '../../../../../components/ContentHub/ContentHubHelper';

const MobileLinks = ({ blogs, title, subheading }) => {
    let blogsElements = [];
    for (let key in blogs) {
        if (Array.isArray(blogs[key])) {
            // Because of the way we pull in title and linked blogs from contentful we need to filter it out

            const title = blogs[key + 'Title'];
            blogsElements.push(
                <IwAccordion
                    className={styles.Accordion}
                    title={title}
                    color="red">
                    <ul className={styles.List}>
                        {blogs[key].map((blog) => (
                            <li>
                                <GatsbyLink
                                    to={getContenthubArticleFullSlug(
                                        blog.categoryTag.categoryTag,
                                        blog.slug
                                    )}>
                                    {blog.displayName || blog.name}
                                </GatsbyLink>
                            </li>
                        ))}
                    </ul>
                </IwAccordion>
            );
        }
    }

    return (
        <div className={styles.accordionContainer}>
            <h1>{title}</h1>
            <div className={styles.Divider}></div>
            <p>{subheading}</p>
            {blogsElements}
        </div>
    );
};

export default MobileLinks;
