import React from 'react';
import LinkShapes from './shapes/shapes';
import LinkColumn from './LinkColumn/LinkColumn';
import styles from './DesktopLinks.module.scss';

const DesktopLinks = ({ blogs, title, subheading }) => {
    let blogsElements = [];
    for (let key in blogs) {
        if (Array.isArray(blogs[key])) {
            // Because of the way we pull in title and linked blogs from contentful we need to filter it out
            const title = blogs[key + 'Title'];
            blogsElements.push(
                <LinkColumn blogColumn={blogs[key]} title={title} />
            );
        }
    }

    return (
        <LinkShapes className={styles.DesktopContainer}>
            <div className={styles.DesktopLinks}>
                <h1>{title}</h1>
                <div className={styles.Divider}></div>
                <p>{subheading}</p>
                <div className={styles.card}>
                    <div className={styles.financeExplainedLinks}>
                        {blogsElements}
                    </div>
                </div>
            </div>
        </LinkShapes>
    );
};

export default DesktopLinks;
