import React from 'react';
import styles from './LinkColumn.module.scss';
import GatsbyImg from 'gatsby-image';
import GatsbyLink from 'gatsby-link';
import { getContenthubArticleFullSlug } from '../../../../../../components/ContentHub/ContentHubHelper';

const LinkColumn = ({ blogColumn, title }) => {
    const topBlog = blogColumn[0];

    return (
        <div className={styles.financeExplainedLinksList}>
            <h3>{title}</h3>
            <ul className={styles.List}>
                {blogColumn.map((blog) => (
                    <li>
                        <GatsbyLink
                            to={getContenthubArticleFullSlug(
                                blog.categoryTag.categoryTag,
                                blog.slug
                            )}>
                            {blog.displayName || blog.name}
                        </GatsbyLink>
                    </li>
                ))}
            </ul>
            <div className={styles.BlogImageContainer}>
                <div className={styles.BlogImageWrapper}>
                    <GatsbyLink
                        to={getContenthubArticleFullSlug(
                            topBlog.categoryTag.categoryTag,
                            topBlog.slug
                        )}>
                        <GatsbyImg
                            fluid={topBlog.articleHeaderImage.fluid}
                            className={styles.BlogImage}
                        />
                        <h3>{topBlog.displayName || topBlog.name}</h3>
                    </GatsbyLink>
                </div>
            </div>
        </div>
    );
};

export default LinkColumn;
