import React from 'react';

const InfoIcon = ({ className, background = '#4D809E', icon = '#ffffff' }) => {
    return (
        <svg
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                r="7.5"
                transform="matrix(1 0 0 -1 8 8)"
                fill={background}
                stroke={background}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 13C7.44772 13 7 12.5523 7 12L7 7C7 6.44772 7.44772 6 8 6C8.55228 6 9 6.44772 9 7L9 12C9 12.5523 8.55229 13 8 13Z"
                fill={icon}
            />
            <circle
                r="0.5"
                transform="matrix(1 0 0 -1 8 4)"
                fill={icon}
                stroke={icon}
            />
        </svg>
    );
};

export default InfoIcon;
