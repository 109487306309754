import React from 'react';

const TickInCircleSVG = ({
    fill = '#184681',
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            {...props}
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.9999 0C4.49996 0 0 4.49996 0 9.9999C0 15.4998 4.49996 19.9998 9.9999 19.9998C15.4998 19.9998 19.9998 15.4998 19.9998 9.9999C19.9998 4.49996 15.4998 0 9.9999 0ZM8.74991 14.2499L4.49996 9.9999L6.24994 8.24992L8.74991 10.7499L13.7499 5.74994L15.4998 7.49993L8.74991 14.2499Z"
                fill={fill}
            />
        </svg>
    );
};

export default TickInCircleSVG;
