import React from 'react';

const ForbesLogo = () => (
    <svg
        width="109"
        height="28"
        viewBox="0 0 109 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M65.8239 7.79475C64.0854 7.79475 62.7199 8.16769 61.3536 8.91214C61.3536 4.93818 61.3536 1.33716 61.4784 0.0957031L53.1568 1.58531V2.33047L54.0261 2.45455C55.1427 2.70269 55.5171 3.32306 55.7631 4.81411C56.0134 7.79475 55.8879 23.6913 55.7631 26.2997C57.9986 26.7967 60.3589 27.1697 62.7192 27.1697C69.3016 27.1697 73.2755 23.0709 73.2755 16.6126C73.2755 11.5213 70.0467 7.79475 65.8239 7.79475ZM62.9673 25.8027C62.4703 25.8027 61.8485 25.8027 61.477 25.6794C61.3522 23.9409 61.2288 16.6126 61.3522 10.155C62.0974 9.90616 62.5937 9.78209 63.2155 9.78209C65.9494 9.78209 67.4397 13.0109 67.4397 16.9841C67.439 21.9535 65.5757 25.8027 62.9673 25.8027ZM21.8586 0.592H0V1.70938L1.24218 1.83346C2.85586 2.08161 3.47766 3.07492 3.7251 5.43448C4.09875 9.90545 3.97396 17.9789 3.7251 21.8273C3.47695 24.1876 2.85658 25.3057 1.24218 25.4283L0 25.6779V26.6712H14.034V25.6779L12.5437 25.4283C10.9293 25.305 10.3082 24.1876 10.0601 21.8273C9.93526 20.2122 9.81119 17.6038 9.81119 14.3757L12.7918 14.4998C14.6551 14.4998 15.5236 15.9901 15.8966 18.1008H17.0147V9.28579H15.8966C15.5229 11.3972 14.6551 12.8868 12.7918 12.8868L9.8119 13.0116C9.8119 8.78878 9.93598 4.81482 10.0608 2.45526H14.407C17.7613 2.45526 19.4997 4.56596 20.7412 8.29248L21.9834 7.91954L21.8586 0.592Z"
            fill="#4D809E"
        />
        <path
            d="M28.9371 7.54581C35.1473 7.54581 38.252 11.7687 38.252 17.357C38.252 22.6973 34.7743 27.1697 28.5642 27.1697C22.3547 27.1697 19.25 22.9461 19.25 17.357C19.25 12.0168 22.7269 7.54581 28.9371 7.54581ZM28.5642 8.78799C25.8317 8.78799 25.0865 12.5138 25.0865 17.357C25.0865 22.0762 26.3287 25.9261 28.813 25.9261C31.6675 25.9261 32.4141 22.2002 32.4141 17.357C32.4141 12.6379 31.1733 8.78799 28.5642 8.78799ZM74.8883 17.4818C74.8883 12.3897 78.1171 7.54581 84.4513 7.54581C89.666 7.54581 92.1511 11.3964 92.1511 16.4878H80.6007C80.4759 21.0829 82.7114 24.4365 87.1831 24.4365C89.1705 24.4365 90.1638 23.9409 91.4067 23.0709L91.9022 23.6913C90.6601 25.4298 87.9283 27.1697 84.4513 27.1697C78.8623 27.169 74.8883 23.195 74.8883 17.4818ZM80.6007 15.1223L86.438 14.9975C86.438 12.5138 86.065 8.78799 83.9543 8.78799C81.8436 8.78799 80.7241 12.265 80.6007 15.1223ZM106.932 8.53984C105.442 7.91804 103.455 7.54581 101.22 7.54581C96.6257 7.54581 93.7677 10.2776 93.7677 13.5071C93.7677 16.7367 95.8769 18.1022 98.8612 19.0962C101.965 20.2129 102.835 21.0829 102.835 22.5732C102.835 24.0635 101.717 25.429 99.7297 25.429C97.3708 25.429 95.6309 24.0635 94.2654 20.337L93.3954 20.5866L93.5202 26.0509C95.0106 26.6712 97.7423 27.169 100.103 27.169C104.947 27.169 108.052 24.6839 108.052 20.8347C108.052 18.2263 106.685 16.7367 103.456 15.4938C99.9778 14.1282 98.7371 13.259 98.7371 11.6439C98.7371 10.0295 99.8566 8.91135 101.347 8.91135C103.581 8.91135 105.071 10.2776 106.191 13.6312L107.059 13.3831L106.932 8.53984ZM54.3967 8.04283C52.286 6.80208 48.5595 7.42174 46.5729 11.8934L46.6962 7.54653L38.3761 9.16093V9.9061L39.2453 10.0302C40.3641 10.155 40.8597 10.7753 40.9845 12.3905C41.2326 15.3711 41.1086 20.588 40.9845 23.1957C40.8597 24.6846 40.3641 25.4298 39.2453 25.5546L38.3761 25.6793V26.6727H49.9257V25.6793L48.4354 25.5546C47.1939 25.4298 46.821 24.6846 46.6962 23.1957C46.4481 20.8354 46.4481 16.1163 46.5729 13.1356C47.1932 12.2657 49.9257 11.5212 52.4094 13.1356L54.3967 8.04283Z"
            fill="#4D809E"
        />
    </svg>
);

export default ForbesLogo;
