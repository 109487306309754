import React from 'react';

import styles from './Links.module.scss';

import DesktopLinks from './DesktopLinks/DesktopLinks';
import MobileLinks from './MobileLinks/MobileLinks';

const Links = ({ blogs, title, subheading }) => {
    return (
        <section className={styles.Links}>
            <MobileLinks blogs={blogs} title={title} subheading={subheading} />
            <DesktopLinks blogs={blogs} title={title} subheading={subheading} />
        </section>
    );
};

export default Links;
