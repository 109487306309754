import React from 'react';
import styles from './shapes.module.scss';
import {
    BasicDoughnut,
    BasicCircle,
    BasicSquare
} from '../../../../../../SVGComponents/shapes/index';

const shapes = ({ children, className }) => {
    return (
        <div className={className}>
            <BasicCircle className={styles.BasicCircle} color="london75" />
            <BasicSquare className={styles.BasicSquareCoral} />
            <BasicSquare
                className={styles.BasicSquareMercury}
                color="denim65"
            />
            <BasicSquare className={styles.BasicSquareLondon} color="london" />
            {/* <BasicDoughnut
                className={styles.BasicDoughnutSnow}
                size="lg"
                color="snow"
            /> */}
            <BasicDoughnut
                className={styles.BasicDoughnutDenim65}
                color="london"
            />
            {children}
        </div>
    );
};

export default shapes;
