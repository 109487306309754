import React from 'react';
import styles from './PressLogos.module.scss';
import ForbesLogo from './svg/ForbesLogo';
import FinancialTimesLogo from './svg/FinancialTimesLogo';
import WiredLogo from './svg/WiredLogo';
import YahooFinanceLogo from './svg/YahooFinanceLogo';

const PressLogos = () => {
    return (
        <section className={styles.background}>
            <div className={styles.PressLogos}>
                <ForbesLogo />
                <FinancialTimesLogo />
                <WiredLogo />
                <YahooFinanceLogo />
            </div>
        </section>
    );
};

export default PressLogos;
